/* styles.css (or whichever CSS file you have) */
.bot-container {
    background-color: rgba(11, 144, 221, 0.392); /* Light grey, replace with any color you like */
    padding: 5px;
    border-color:rgb(255, 98, 0);
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}
.bot-container:first-child {
    margin-right: 10px; /* Adjust as needed */
}


.bot-container:last-child {
    margin-left: 10px; /* Adjust as needed */
}

.bot-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icon-toggle {
    cursor: pointer;
    color: #ffb300; /* Adjust as needed */
    margin-left: 1rem;
}

.subtitle {
    font-size: 1em;
    color: rgb(2, 15, 21);
  }