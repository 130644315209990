/* Styling for the bevel button */
.bevel-button {
    background-color: #f0f0f0; /* Light background color */
    border: 1px solid #999;    /* Slightly darker border */
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 4px;
    color: #333;
    cursor: pointer;
    box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.3), /* Inner shadow for bevel */
                inset -2px -2px 5px rgba(255, 255, 255, 0.8); /* Highlight on top left */
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .bevel-button:active {
    background-color: #e0e0e0; /* Darker background when pressed */
    box-shadow: inset -2px -2px 5px rgba(0, 0, 0, 0.3), /* Reverse the bevel */
                inset 2px 2px 5px rgba(255, 255, 255, 0.8); /* Reverse the highlight */
  }
  
  .bevel-button:hover {
    background-color: #e8e8e8; /* Slightly darker on hover */
  }  

  .emoticon-container {
    text-align: center;
    font-size: 100px;
    padding: 50px;
  }
  
  h1 {
    margin: 0;
    padding: 0;
  }  