.request-box {
    border: 1px solid rgb(2, 12, 2);
    border-radius: 5px;
    padding: 10px;

}


.response-box {
    border: 1px solid rgb(3, 17, 3);
    border-radius: 5px;
    padding: 10px;

}