/* MenuBar.css */
.menu-bar {
    display: flex;  /* Aligns the list items horizontally */
    list-style: none;  /* Remove default bullet points */
    justify-content: left;  /* Centers the menu items */
    padding: 15px 0;  /* Adds padding for better spacing */
    background-color: #001f3f;  /* Dark bluish background color */
    margin: 0;  /* Removes default margin */
  }
  
  .menu-bar li {
    margin: 0 15px;  /* Space between items */
  }
  
  .menu-bar a {
    text-decoration: none;  /* Remove underline */
    color: #666161;  /* White text color to contrast the dark background */
    font-weight: bold;  /* Bold text */
  }
  
  .menu-bar a:hover {
    color: #ff6600;  /* Change color when hovering over a menu item */
  }
  