.qa-tiles-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .qa-tile {
    cursor: pointer;
    padding: 15px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .qa-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    color: #363434;
  }
  
  .qa-question .arrow {
    font-size: 18px;
    transition: transform 0.3s ease;
  }
  
  .qa-tile.expanded .arrow {
    transform: rotate(180deg); /* Rotate arrow when expanded */
  }
  
  .qa-answer {
    margin-top: 10px;
    font-size: 14px;
    color: #090101;
  }  