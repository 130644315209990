/* Ensures all cards have the same minimum height */
.card-equal-height {
    min-height: 200px;  /* Set a minimum height for all cards */
}

.card-content {
    padding-bottom: 50px;  /* Add space between content and button */
}

/* Subtitle adjustments for visibility */
.subtitle {
    margin-top: 10px;  /* Add margin above subtitle */
    color: #4a4a4a;  /* Set a visible dark grey color */
    font-size: 1.25rem;  /* Increase font size for better visibility */
}

/* Center the button */
.card-footer {
    display: flex;
    justify-content: center;  /* Center the button horizontally */
}

/* Button with beveled border effect */
button {
    width: auto;  /* Ensure buttons have natural width */
    padding: 0.5rem 1.5rem;  /* Add padding for a standard button size */
    background-color: #3273dc;  /* Default button background */
    color: white;  /* Button text color */
    border: 2px solid #4a4a4a;  /* Outer border for the button */
    border-radius: 8px;  /* Add rounded corners */
    border-top-color: #ffffff;  /* Lighter color on top border for bevel effect */
    border-left-color: #ffffff;  /* Lighter color on left border for bevel effect */
    border-right-color: #2e2e2e;  /* Darker color on right border for bevel effect */
    border-bottom-color: #2e2e2e;  /* Darker color on bottom border for bevel effect */
}

button:hover {
    background-color: #275ba8;  /* Darken the background on hover */
    border-top-color: #2e2e2e;  /* Invert the bevel effect on hover */
    border-left-color: #2e2e2e;  /* Invert the bevel effect on hover */
    border-right-color: #ffffff;  /* Invert the bevel effect on hover */
    border-bottom-color: #ffffff;  /* Invert the bevel effect on hover */
}
